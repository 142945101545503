import { FC, PropsWithChildren } from 'react';

import { useIsClient } from 'src/hooks/useIsClient';
import { useIsIframeView } from 'src/hooks/useIsIframeView';

const VkMiniAppsLinkDisable: FC<PropsWithChildren> = ({ children }) => {
    const isIframeView = useIsIframeView();

    const isClient = useIsClient();

    if (!isClient && isIframeView) {
        return <span className="vk-mini-apps-link-disable">{children}</span>;
    }

    return <>{children}</>;
};

export default VkMiniAppsLinkDisable;
