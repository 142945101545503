import { useState, useCallback } from 'react';

import { LoginSteps } from 'src/components/AccountLogin/types';

export { LoginSteps };

const useLoginStep = (initalStep: LoginSteps = LoginSteps.Login) => {
    const [step, _setStep] = useState<LoginSteps>(initalStep);
    const [previousStep, setPreviousStep] = useState<LoginSteps>(initalStep);

    const setStep = useCallback(
        (newStep: LoginSteps) => {
            setPreviousStep(step);
            _setStep(newStep);
        },
        [step]
    );

    const onPreviousStep = useCallback(() => {
        _setStep(previousStep);
    }, [previousStep]);

    return { step, setStep, previousStep, onPreviousStep } as const;
};

export { useLoginStep };
