import { useCallback, useEffect } from 'react';
import { Field } from 'react-final-form';

import { Link, VSpacing, Button, Input, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatSeconds } from 'Utils/Dates';
import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useTimer } from 'src/hooks/useTimer';
import { fetcher } from 'src/utils/fetcher';

import resendErrorNotification from 'src/components/AccountLogin/steps/TwoFactorStep/AccountLoginResendError';

const TWO_FACTOR_URL = '/account/login/two_factor_event';

declare global {
    interface FetcherPostApi {
        [TWO_FACTOR_URL]: {
            queryParams: void;
            body: { username: string; event: string };
            response: void;
        };
    }
}

interface TwoFactorCodeSenderProps {
    onResendCode: () => Promise<void>;
    onNoAccessToEmail: () => void;
}

const TrlKeys = {
    placeholder: 'auth.twoFactor.code.placeholder',
    timerText: 'auth.twoFactor.timer.text',
    resend: 'auth.twoFactor.timer.resend',
    noAccessToEmail: 'auth.twoFactor.noAccessEmail.button',
    submit: 'auth.twoFactor.submit',
    DEFAULT_ERROR: 'auth.twoFactor.error.unknown',
    CODE_NOT_FOUND: 'auth.twoFactor.error.CODE_NOT_FOUND',
    WRONG_CODE: 'auth.twoFactor.error.WRONG_CODE',
    CODE_EXPIRED: 'auth.twoFactor.error.CODE_EXPIRED',
    valueMissing: 'auth.twoFactor.error.VALUE_MISSING',
};

const TwoFactorCodeSender: TranslatedComponent<TwoFactorCodeSenderProps> = ({
    onResendCode,
    onNoAccessToEmail,
    trls,
}) => {
    const { username = '', isSubmitting, twoFactorTimer, confirmError } = useLoginContext();
    const { addNotification } = useNotification();
    const [timer, setTimer] = useTimer(0);

    const resendCodeHandler = useCallback(async () => {
        fetcher.postFormData(TWO_FACTOR_URL, { username, event: 'resend' }).catch(console.error);
        try {
            await onResendCode();
        } catch (_) {
            addNotification(resendErrorNotification);
        }
    }, [addNotification, onResendCode, username]);

    useEffect(() => {
        void fetcher.postFormData(TWO_FACTOR_URL, { username, event: 'shown' });
    }, [username]);

    useEffect(() => {
        if (!twoFactorTimer) {
            return;
        }

        setTimer(twoFactorTimer);
    }, [setTimer, twoFactorTimer]);

    const timerSection =
        timer > 0 ? (
            <Text style="tertiary">
                <span suppressHydrationWarning>{format(trls[TrlKeys.timerText], { '{0}': formatSeconds(timer) })}</span>
            </Text>
        ) : (
            <Link onClick={resendCodeHandler}>{trls[TrlKeys.resend]}</Link>
        );

    return (
        <>
            <Field
                name="code2fa"
                type="number"
                render={({ input, meta }) => {
                    const isInvalid = meta.invalid && meta.submitFailed;
                    const errorTrlKey = (isInvalid ? meta.error : confirmError) as keyof typeof TrlKeys;
                    const errorTranslated = trls[TrlKeys[errorTrlKey] || TrlKeys.DEFAULT_ERROR];
                    return (
                        <Input
                            {...input}
                            size="large"
                            name="code2fa"
                            placeholder={trls[TrlKeys.placeholder]}
                            data-qa="login-form-code-2fa"
                            invalid={!!confirmError || isInvalid}
                            errorMessage={errorTranslated}
                        />
                    );
                }}
            />
            {timerSection}
            <VSpacing default={24} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                data-qa="account-login-submit"
                stretched
                type="submit"
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
            <VSpacing default={12} />
            <Button
                size="large"
                style="accent"
                mode="tertiary"
                data-qa="account-login-no-access-email"
                stretched
                onClick={onNoAccessToEmail}
            >
                {trls[TrlKeys.noAccessToEmail]}
            </Button>
        </>
    );
};

export default translation(TwoFactorCodeSender);
